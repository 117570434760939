import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// Import the AngularFire Module
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx'
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ThemeableBrowser} from '@ionic-native/themeable-browser/ngx';




@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({swipeBackEnabled: false}),
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
    
    
  ],
    
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },File,FileOpener,MediaCapture,StreamingMedia,InAppBrowser,ThemeableBrowser],
  bootstrap: [AppComponent],
})
export class AppModule {}

