import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'actividad-form',
    loadChildren: () => import('./actividad-form/actividad-form.module').then( m => m.ActividadFormPageModule)
  },
  {
    path: 'actividades-list',
    loadChildren: () => import('./actividades-list/actividades-list.module').then( m => m.ActividadesListPageModule)
  },
  {
    path: 'actividad-edit/:id',
    loadChildren: () => import('./actividad-edit/actividad-edit.module').then( m => m.ActividadEditPageModule)
  },
  {
    path: 'actividades-list-details',
    loadChildren: () => import('./actividades-list-details/actividades-list-details.module').then( m => m.ActividadesListDetailsPageModule)
  },
  {
    path: 'registro-form/:id',
    loadChildren: () => import('./registro-form/registro-form.module').then( m => m.RegistroFormPageModule)
  },
  {
    path: 'reportes-list',
    loadChildren: () => import('./reportes-list/reportes-list.module').then( m => m.ReportesListPageModule)
  },
  {
    path: 'reporte-view/:id',
    loadChildren: () => import('./reporte-view/reporte-view.module').then( m => m.ReporteViewPageModule)
  },
  {
    path: 'photo-list/:id/:flag',
    loadChildren: () => import('./photo-list/photo-list.module').then( m => m.PhotoListPageModule)
  },
  {
    path: 'photo-details',
    loadChildren: () => import('./photo-details/photo-details.module').then( m => m.PhotoDetailsPageModule)
  },
  {
    path: 'agenda-modal',
    loadChildren: () => import('./modals/agenda-modal/agenda-modal.module').then( m => m.AgendaModalPageModule)
  },
  {
    path: 'agenda-edit-modal',
    loadChildren: () => import('./modals/agenda-edit-modal/agenda-edit-modal.module').then( m => m.AgendaEditModalPageModule)
  },
  {
    path: 'audio-list/:id/:flag',
    loadChildren: () => import('./audio-list/audio-list.module').then( m => m.AudioListPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'recursos',
    loadChildren: () => import('./recursos/recursos.module').then( m => m.RecursosPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
